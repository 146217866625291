import React, { useRef, useState } from "react";
import { cn } from "../utils";
import { IconGridProps } from "../types/IconGrid.interface";
import { ChevronDown, ChevronUp } from "lucide-react";

export default function IconGrid({
    gridItems,
    wrapperClass,
    isPreventive = false,
    initialViewableCount = 4,
    isLargeScreen,
    itemClass
}: IconGridProps & { isPreventive?: boolean; initialViewableCount?: number; isLargeScreen?: boolean; itemClass?: string }) {
    const gridRef = useRef<HTMLDivElement>(null);
    const isBrowser = typeof window !== `undefined`;
    const initialScrollOffset = isBrowser ? window.scrollY : 0;
    const [showMore, setShowMore] = useState(false);
    const [scrollOffset, setScrollOffset] = useState(initialScrollOffset);

    const handleShowMore = () => {
        if (isBrowser) {
            setScrollOffset(window.scrollY);
        }
        setShowMore(true);
    };

    const handleShowLess = () => {
        setShowMore(false);
        if (isBrowser) {
            window.scrollTo({ top: scrollOffset, left: 0, behavior: "auto" });
        }
    };

    const displayedItems = showMore || isPreventive ? gridItems : gridItems.slice(0, initialViewableCount);

    return (
        <>
            {!!gridItems.length && (
                <div ref={gridRef} className={cn(isPreventive ? "grid w-full gap-4" : "grid w-full gap-4 md:flex md:flex-wrap md:justify-center", wrapperClass)}>
                    {displayedItems.map(({ icon, text, amount }, index) => (
                        <React.Fragment key={`grid-item-${index + 1}`}>
                            <div className={`flex w-full items-center text-xs ${isPreventive ? "gap-2" : `flex-col text-center md:w-[160px] xl:w-[150px] ${itemClass}`}`}>
                                <div className="flex w-full justify-between">
                                    <div className={`flex ${isPreventive ? "flex-row" : "flex-col"} w-full items-center gap-1 text-center`}>
                                        <div className="justify-center">{icon}</div>
                                        {!!text && (
                                            <span className={`${isPreventive ? "text-left" : "line-clamp-2 whitespace-pre text-center md:mx-3 lg:mx-0"} font-medium leading-5`}>
                                                {text}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                {!!amount && <span className="ml-auto sm:ml-2">${amount}</span>}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            )}

            {!!initialViewableCount && gridItems.length > initialViewableCount && !isLargeScreen && !isPreventive && (
                <div className="col-span-2 flex items-center justify-center">
                    <button type="button" className="mt-4 flex items-center justify-center" onClick={!showMore ? handleShowMore : handleShowLess}>
                        <div className="text-md text-type-primary mr-2">{!showMore ? `Show More` : `Show Less`}</div>
                        {!showMore ? <ChevronDown /> : <ChevronUp />}
                    </button>
                </div>
            )}
        </>
    );
}
