import { useMemo } from "react";
import { useQuery, useMutationState } from "@tanstack/react-query";
import axios from "axios";
import { QuoteAPI } from "../utils/QuoteAPI";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { Quote, Policy } from "../types/Quote.interface";
import { Coverage } from "spot-types/entities/Coverage";
import { PublicConfig } from "../PublicConfig";
/**
 * `usePetPlans` Custom Hook
 *
 * This hook is designed to manage the fetching, updating, and default state of CA Pet Plans.
 *
 * @param quoteId - The ID of the quote to fetch.
 * @param petId - The ID of the pet to fetch.
 * @param underwriter - Underwriter type.
 *
 * @returns
 *  - petPlansQuery: The React Query object containing the current quote state, refetch, etc.
 *  - updatePlan: todo ...
 *  - isPetPlansUpdating: todo A boolean flag indicating if any plan mutation is currently pending.
 */

export type PetPlan = { baseCoverage: Coverage; riders: Coverage[] };
export const usePetPlans = (quote: Quote | undefined, underwriter: PetUnderwriterType) => {
    const quoteApi = new QuoteAPI(underwriter);
    const { id: quoteId, policies, ratingZipcode } = quote || {};

    const policiesKey = useMemo(() => {
        return policies ? serializePoliciesForQueryKey(policies) : null;
    }, [policies]);

    const petPlansQuery = useQuery<Record<string, PetPlan[]>>({
        queryKey: ["petPlans", quoteId, ratingZipcode, policiesKey],
        queryFn: async ({ queryKey }) => {
            const [, quoteId, ratingZipcode, policiesStringified] = queryKey;

            if (typeof quoteId !== "string") {
                throw new Error("quoteId must be a string");
            }

            if (typeof ratingZipcode !== "string") {
                throw new Error("ratingZipcode must be a string");
            }

            let parsedPolicies: Policy[] = [];
            if (typeof policiesStringified === "string") {
                parsedPolicies = JSON.parse(policiesStringified);
            }

            const allPetIds = parsedPolicies.map(policy => policy.id).filter(Boolean) as string[];

            // Create an array of promises for all pet plans, resolving undefined to empty arrays
            const planPromises = allPetIds.map(
                petId =>
                    quoteApi
                        .getPlans(quoteId, petId)
                        .then(plan => ({ petId, plan: plan || [] })) // Embed petId in the resolution to track which ID each response belongs to
                        .catch(() => ({ petId, plan: [] as PetPlan[] })) // In case of error, return an empty plan array for the petId
            );

            try {
                // Wait for all promises to resolve
                const resolvedPlans = await Promise.all(planPromises);

                // Filter plans based on underwriter and annualLimit condition
                const filteredAndTransformedPlans = resolvedPlans.map(({ petId, plan }) => {
                    if (underwriter === "ptz-us") {
                        const transformedPlan = plan
                            .map(p => {
                                if (p.baseCoverage.details?.annualLimit?.value === PublicConfig.UNLIMITED_ANNUAL_LIMIT_VALUE) {
                                    // Transforming the value and symbol for annualLimit
                                    return {
                                        ...p,
                                        baseCoverage: {
                                            ...p.baseCoverage,
                                            details: {
                                                ...p.baseCoverage.details,
                                                annualLimit: {
                                                    symbol: "Unlimited",
                                                    value: PublicConfig.UNLIMITED_ANNUAL_LIMIT_VALUE
                                                }
                                            }
                                        }
                                    };
                                }
                                return p;
                            })
                            .filter(p => {
                                const annualLimitValue = p.baseCoverage.details?.annualLimit?.value;
                                return typeof annualLimitValue === `number` && (annualLimitValue <= 10000 || annualLimitValue === 0);
                            })
                            .filter(p => {
                                const deductible = p.baseCoverage.details?.deductible?.value;
                                return typeof deductible === `number` && deductible !== 300;
                            });
                        return { petId, plan: transformedPlan };
                    }
                    return { petId, plan };
                });

                // Reduce the filteredAndTransformedPlans into a Record, using petId as keys
                const petPlansRecord = filteredAndTransformedPlans.reduce(
                    (acc, { petId, plan }) => {
                        acc[petId] = plan;
                        return acc;
                    },
                    {} as Record<string, PetPlan[]>
                );

                return petPlansRecord;
            } catch (err) {
                // Since we're handling individual promise errors, this catch is redundant but kept for safety
                if (axios.isAxiosError(err)) throw err;
                console.error("Unexpected error in fetching pet plans:", err);
                throw new Error("Failed to fetch pet plans");
            }
        }
    });

    const mutationState = useMutationState({ filters: { status: `pending`, mutationKey: [`petPlans`] } });

    return { petPlansQuery, isPetPlansUpdating: mutationState.length > 0 };
};

function serializePoliciesForQueryKey(policies: Policy[]): string {
    return JSON.stringify(
        policies.map(({ id, species, breedID, gender, age }) => ({
            id,
            species,
            breedID,
            gender,
            age
        }))
    );
}
