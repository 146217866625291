// Typessrc/media
import { IconGridItem } from "../types/IconGrid.interface";
import { Species } from "spot-types/entities/Species";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";

// Icons
import BillIcon from "@/shared/media/icons/policy/Bill.svg";
import CatXrayIcon from "@/shared/media/icons/policy/Cat-xray.svg";
import DogXrayIcon from "@/shared/media/icons/policy/Dog-xray.svg";
import HospitalIcon from "@/shared/media/icons/policy/Hospital.svg";
import PawHeartbeatIcon from "@/shared/media/icons/policy/Paw-Heartbeat.svg";
import HipIcon from "@/shared/media/icons/policy/Hips.svg";
import BrokenBoneIcon from "@/shared/media/icons/policy/Bone-Broken.svg";
import PillBottleIcon from "@/shared/media/icons/policy/Pill-Bottle.svg";
import AlternativeIcon from "@/shared/media/icons/policy/Alternative-Therapy.svg";
import BandaidsIcon from "@/shared/media/icons/policy/Bandaids.svg";
import BoneBrokenIcon from "@/shared/media/icons/policy/Bone-Broken.svg";
import CancerIcon from "@/shared/media/icons/policy/Cancer.svg";
import ClipboardMagnifyingGlassIcon from "@/shared/media/icons/policy/ClipboardMagnifyingGlass.svg";
import DentalDiseaseIcon from "@/shared/media/icons/policy/Dental-Disease.svg";
import DogLeashIcon from "@/shared/media/icons/policy/Dog-Leash.svg";
import DogSkinMagnifyIcon from "@/shared/media/icons/policy/Dog-Skin-Magnify.svg";
import CatSkinMagnifyIcon from "@/shared/media/icons/policy/Cat-Skin-Magnify.svg";
import HipsIcon from "@/shared/media/icons/policy/Hips.svg";
import IVIcon from "@/shared/media/icons/policy/IV.svg";
import MicrochipDogIcon from "@/shared/media/icons/policy/Microchip-Dog.svg";
import MicrochipCatIcon from "@/shared/media/icons/policy/Microchip-Cat.svg";
import PawHeartBeatIcon from "@/shared/media/icons/policy/Paw-Heartbeat.svg";
import ParasiteIcon from "@/shared/media/icons/policy/Parasite.svg";
import PoisonIcon from "@/shared/media/icons/policy/Poison.svg";
import ShotSyringeIcon from "@/shared/media/icons/policy/Shot-Syringe.svg";
import ShotVaccineIcon from "@/shared/media/icons/policy/Shot-Vaccine.svg";
import StomachIcon from "@/shared/media/icons/policy/Stomach.svg";
import SurgeryIcon from "@/shared/media/icons/policy/Surgery.svg";
import TapewormIcon from "@/shared/media/icons/policy/Tapeworm.svg";
import BrokenToothIcon from "@/shared/media/icons/policy/BrokenTooth.svg";
import TelehealthIcon from "@/shared/media/icons/policy/Telehealth.svg";
import WarningIcon from "@/shared/media/icons/policy/Warning.svg";
import ToothBrushIcon from "@/shared/media/icons/policy/Tooth-Brush.svg";
import CalendarHeartIcon from "@/shared/media/icons/policy/Calendar-Heart.svg";
import DiagnosticsIcon from "@/shared/media/icons/policy/ClipboardMagnifyingGlass.svg";
import HealthCrossPawIcon from "@/shared/media/icons/policy/Health-Cross-Paw.svg";

const iconSize = "size-16";
const preventiveIconSize = "size-10";

type BenefitLevel = "gold" | "platinum";

interface PreventiveBenefit {
    text: string;
    amountGold: number;
    amountPlatinum: number;
    icon: JSX.Element;
    level: BenefitLevel[];
}

export class IconUtils {
    static getAccidentIcons = (species: Species, underwriter: PetUnderwriterType): IconGridItem[] => {
        switch (underwriter) {
            case "ptz-us":
                return [
                    {
                        icon: <BillIcon className={iconSize} />,
                        text: `Vet Exam Fees\n& Lab Tests`
                    },
                    {
                        icon: species === Species.Dog ? <DogXrayIcon className={iconSize} /> : <CatXrayIcon className={iconSize} />,
                        text: `MRI or CT Scans\n& X-rays`
                    },
                    {
                        icon: <HospitalIcon className={iconSize} />,
                        text: `Surgery &\nHospitalization`
                    },
                    {
                        icon: <IVIcon className={iconSize} />,
                        text: `IV Fluids &\nMedications`
                    },
                    {
                        icon: <BandaidsIcon className={iconSize} />,
                        text: `Medical Supplies\n(e.g. Bandages and more)`
                    },
                    {
                        icon: <PoisonIcon className={iconSize} />,
                        text: `Poison Control\nConsultation Fees`
                    },
                    {
                        icon: <PillBottleIcon className={iconSize} />,
                        text: `Prescription\nMedications`
                    },
                    {
                        icon: <BrokenToothIcon className={iconSize} />,
                        text: `Broken\nTeeth`
                    },
                    {
                        icon: <ShotSyringeIcon className={iconSize} />,
                        text: `Advanced\nCare`
                    },
                    {
                        icon: <AlternativeIcon className={iconSize} />,
                        text: `Alternative\nTreatments`
                    }
                ];

            default:
                return [];
        }
    };

    static getAccidentIllnessIcons(species: Species, underwriter: PetUnderwriterType): IconGridItem[] {
        switch (underwriter) {
            case "ptz-ca":
                return [
                    {
                        icon: <BrokenBoneIcon className={iconSize} />,
                        text: `Accidents &\nIllnesses`
                    },
                    {
                        icon: <PawHeartbeatIcon className={iconSize} />,
                        text: `Hereditary & Breed\nSpecific Conditions`
                    },
                    {
                        icon: <BillIcon className={iconSize} />,
                        text: `Exam &\nDiagnostic Fees`
                    },
                    {
                        icon: species === Species.Dog ? <DogXrayIcon className={iconSize} /> : <CatXrayIcon className={iconSize} />,
                        text: `Lab Tests, X-rays,\n& Ultrasounds`
                    },
                    {
                        icon: <HospitalIcon className={iconSize} />,
                        text: `Hospital Stays\n& Surgeries`
                    },
                    {
                        icon: <PillBottleIcon className={iconSize} />,
                        text: `Medications &\nTherapeutic Food`
                    },
                    {
                        icon: <HipIcon className={iconSize} />,
                        text: `Physical &\nRehabilitative Therapies`
                    },
                    {
                        icon: <AlternativeIcon className={iconSize} />,
                        text: `Alternative\nTreatments`
                    }
                ];

            case "ptz-us":
                return [
                    {
                        icon: <BillIcon className={iconSize} />,
                        text: `Vet Exam Fees\n& Lab Tests`
                    },
                    {
                        icon: <PillBottleIcon className={iconSize} />,
                        text: `Prescription\nMedications & Food`
                    },
                    {
                        icon: species === Species.Dog ? <DogXrayIcon className={iconSize} /> : <CatXrayIcon className={iconSize} />,
                        text: `X-rays, MRIs,\n& CT Scans`
                    },
                    {
                        icon: species === Species.Dog ? <DogSkinMagnifyIcon className={iconSize} /> : <CatSkinMagnifyIcon className={iconSize} />,
                        text: `Skin, Eye, & Ear\nInfections`
                    },
                    {
                        icon: <CancerIcon className={iconSize} />,
                        text: `Cancer &\nChemotherapy`
                    },
                    {
                        icon: <SurgeryIcon className={iconSize} />,
                        text: `Surgery &\nSpecialized Care`
                    },
                    {
                        icon: <PawHeartBeatIcon className={iconSize} />,
                        text: `Congenital Conditions\n& Heart Disease`
                    },
                    {
                        icon: <HospitalIcon className={iconSize} />,
                        text: `Emergencies &\nHospitalizations`
                    },
                    {
                        icon: species === Species.Dog ? <MicrochipDogIcon className={iconSize} /> : <MicrochipCatIcon className={iconSize} />,
                        text: `Microchip\nImplantation`
                    },
                    {
                        icon: <DogLeashIcon className={iconSize} />,
                        text: `Excessive Licking\n& Fur Pulling`
                    },
                    {
                        icon: <DentalDiseaseIcon className={iconSize} />,
                        text: `Dental Illnesses\n& Gum Disease`
                    },
                    {
                        icon: <StomachIcon className={iconSize} />,
                        text: `Digestive Illnesses\n& Diarrhea`
                    },
                    {
                        icon: <HipsIcon className={iconSize} />,
                        text: `Arthritis &\nHip Dysplasia`
                    },
                    {
                        icon: <ParasiteIcon className={iconSize} />,
                        text: `Infectious Diseases &\nDiseases from Parasites`
                    },
                    {
                        icon: <BoneBrokenIcon className={iconSize} />,
                        text: `Orthopedic\nInjuries`
                    },
                    {
                        icon: <WarningIcon className={iconSize} />,
                        text: `Swallowed Objects\n& Poisons`
                    },
                    {
                        icon: <ClipboardMagnifyingGlassIcon className={iconSize} />,
                        text: `Allergies &\nTreatments`
                    },
                    {
                        icon: <ShotVaccineIcon className={iconSize} />,
                        text: `Urinary Tract\nInfections`
                    },
                    {
                        icon: <TelehealthIcon className={iconSize} />,
                        text: `24/7 Virtual\nVet Visits`
                    },
                    {
                        icon: <AlternativeIcon className={iconSize} />,
                        text: `End of Life Expenses\n(e.g. Euthanasia)`
                    }
                ];

            default:
                return [];
        }
    }

    static PREVENTIVE_BENEFITS_PTZ_CA: PreventiveBenefit[] = [
        {
            text: "Dental Cleaning",
            icon: <ToothBrushIcon className={preventiveIconSize} />,
            level: ["platinum"],
            amountGold: 0,
            amountPlatinum: 125
        },
        {
            text: "Spaying/Neutering and/or Wellness or Pre-Anesthetic Blood Tests",
            amountGold: 90,
            amountPlatinum: 115,
            icon: <SurgeryIcon className={preventiveIconSize} />,
            level: ["gold", "platinum"]
        },
        {
            text: "Wellness Exam",
            amountGold: 60,
            amountPlatinum: 80,
            icon: <CalendarHeartIcon className={preventiveIconSize} />,
            level: ["gold", "platinum"]
        },
        {
            text: "Vaccinations and titers for dogs (DHLPP) and cats (FVRCP)",
            amountGold: 20,
            amountPlatinum: 20,
            icon: <ShotVaccineIcon className={preventiveIconSize} />,
            level: ["gold", "platinum"]
        },
        {
            text: "Heartworm or FeLV Screening",
            amountGold: 25,
            amountPlatinum: 25,
            icon: <ClipboardMagnifyingGlassIcon className={preventiveIconSize} />,
            level: ["gold", "platinum"]
        },
        {
            text: "Rabies, Canine Lyme Disease, and/or Feline FIP Vaccine/Titer",
            amountGold: 20,
            amountPlatinum: 20,
            icon: <ShotVaccineIcon className={preventiveIconSize} />,
            level: ["gold", "platinum"]
        },
        {
            text: "Fecal Screen",
            amountGold: 15,
            amountPlatinum: 20,
            icon: <ClipboardMagnifyingGlassIcon className={preventiveIconSize} />,
            level: ["gold", "platinum"]
        },
        {
            text: "Heartworm/Flea Prevention",
            amountGold: 0,
            amountPlatinum: 75,
            icon: <HealthCrossPawIcon className={preventiveIconSize} />,
            level: ["platinum"]
        },
        {
            text: "Canine Bordetella Vaccine/Titer or Feline FeLV Vaccine/Titer",
            amountGold: 0,
            amountPlatinum: 20,
            icon: <ShotVaccineIcon className={preventiveIconSize} />,
            level: ["platinum"]
        },
        {
            text: "Microchip and/or Urinalysis",
            amountGold: 30,
            amountPlatinum: 30,
            icon: <ClipboardMagnifyingGlassIcon className={preventiveIconSize} />,
            level: ["gold", "platinum"]
        }
    ];

    static PREVENTIVE_BENEFITS_PTZ_US: PreventiveBenefit[] = [
        {
            text: `Dental Cleaning`,
            amountGold: 100,
            amountPlatinum: 150,
            icon: <ToothBrushIcon className={preventiveIconSize} />,
            level: ["gold"]
        },
        {
            text: `Dental Cleaning or Spay/Neuter`,
            amountGold: 100,
            amountPlatinum: 150,
            icon: <ToothBrushIcon className={preventiveIconSize} />,
            level: ["platinum"]
        },
        {
            text: `Wellness Exam`,
            amountGold: 50,
            amountPlatinum: 50,
            icon: <CalendarHeartIcon className={preventiveIconSize} />,
            level: ["gold", "platinum"]
        },
        {
            text: `Dog DHLPP or Cat FVRCP Vaccine/Titer`,
            amountGold: 20,
            amountPlatinum: 25,
            icon: <ShotVaccineIcon className={preventiveIconSize} />,
            level: ["gold", "platinum"]
        },
        {
            text: `Dog Heartworm or FeLV Screening`,
            amountGold: 20,
            amountPlatinum: 25,
            icon: <DiagnosticsIcon className={preventiveIconSize} />,
            level: ["gold", "platinum"]
        },
        {
            text: `Rabies and/or Dog Lyme or Cat FIP Vaccine/Titer`,
            amountGold: 20,
            amountPlatinum: 25,
            icon: <ShotVaccineIcon className={preventiveIconSize} />,
            level: ["gold", "platinum"]
        },
        {
            text: `Deworming`,
            amountGold: 20,
            amountPlatinum: 25,
            icon: <TapewormIcon className={preventiveIconSize} />,
            level: ["gold", "platinum"]
        },
        {
            text: `Fecal Test`,
            amountGold: 20,
            amountPlatinum: 25,
            icon: <DiagnosticsIcon className={preventiveIconSize} />,
            level: ["gold", "platinum"]
        },
        {
            text: `Flea/Heartworm Prevention`,
            amountGold: 0,
            amountPlatinum: 25,
            icon: <HealthCrossPawIcon className={preventiveIconSize} />,
            level: ["platinum"]
        },
        {
            text: `Health Certificate`,
            amountGold: 0,
            amountPlatinum: 25,
            icon: <CalendarHeartIcon className={preventiveIconSize} />,
            level: ["platinum"]
        },
        {
            text: `Dog Bordetella or Cat FELV Vaccine/Titer`,
            amountGold: 0,
            amountPlatinum: 25,
            icon: <ShotVaccineIcon className={preventiveIconSize} />,
            level: ["platinum"]
        },
        {
            text: `Urinalysis`,
            amountGold: 0,
            amountPlatinum: 25,
            icon: <HealthCrossPawIcon className={preventiveIconSize} />,
            level: ["platinum"]
        },
        {
            text: `Blood Test`,
            amountGold: 0,
            amountPlatinum: 25,
            icon: <CalendarHeartIcon className={preventiveIconSize} />,
            level: ["platinum"]
        }
    ];

    static getPreventiveGoldBenefits(underwriter: PetUnderwriterType): IconGridItem[] {
        switch (underwriter) {
            case "ptz-us":
                return IconUtils.PREVENTIVE_BENEFITS_PTZ_US.filter(benefit => benefit.level.includes("gold")).map(benefit => ({
                    text: benefit.text,
                    icon: benefit.icon,
                    amount: benefit.amountGold
                }));

            case "ptz-ca":
                return IconUtils.PREVENTIVE_BENEFITS_PTZ_CA.filter(benefit => benefit.level.includes("gold")).map(benefit => ({
                    text: benefit.text,
                    icon: benefit.icon,
                    amount: benefit.amountGold
                }));

            default:
                return [];
        }
    }

    static getPreventivePlatinumBenefits(underwriter: PetUnderwriterType): IconGridItem[] {
        switch (underwriter) {
            case "ptz-us":
                return IconUtils.PREVENTIVE_BENEFITS_PTZ_US.filter(benefit => benefit.level.includes("platinum")).map(benefit => ({
                    text: benefit.text,
                    icon: benefit.icon,
                    amount: benefit.amountPlatinum
                }));

            case "ptz-ca":
                return IconUtils.PREVENTIVE_BENEFITS_PTZ_CA.filter(benefit => benefit.level.includes("platinum")).map(benefit => ({
                    text: benefit.text,
                    icon: benefit.icon,
                    amount: benefit.amountPlatinum
                }));

            default:
                return [];
        }
    }
}
