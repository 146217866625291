import React, { useMemo, useCallback, FunctionComponent } from "react";
import { Button, Card, Heading } from "@/shared/components/ui";
import IconGrid from "@/shared/components/IconGrid";
import { useModal } from "@/shared/hooks/useModal";
import { IconUtils } from "@/shared/utils/IconUtils";
import { CoverageSettings } from "@/shared/types/Quote.interface";
import { Species } from "spot-types/entities/Species";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import InfoIcon from "@/shared/media/icons/CircleInfoProRegular.svg";
import { DialogProps } from "@/shared/types/Modals";
import { useMediaQuery } from "@react-hookz/web";

interface CoverageIconGridProps {
    coverageInfo: CoverageSettings;
    species?: Species;
    underwriter: PetUnderwriterType;
    samplePolicyUrl: string;
    modalContent?: React.ComponentType<DialogProps>;
}

export function CoverageIconGrid(props: CoverageIconGridProps) {
    const { coverageInfo, species, underwriter, samplePolicyUrl, modalContent } = props;

    const modal = useModal();

    const coverageTypes = coverageInfo.coverages?.map(coverage => coverage.type);
    const hasAccidentAndIllness = coverageTypes?.includes("accident") && coverageTypes?.includes("illness");
    const hasAccidentOnly = coverageTypes?.includes("accident") && !coverageTypes?.includes("illness");

    const getTitle = () => {
        if (hasAccidentAndIllness) return "Spot Accident & Illness plans cover eligible claims for:";
        if (hasAccidentOnly) return "Spot Accident-Only plans cover eligible claims for:";
        return "";
    };

    const coverageIcons = useMemo(() => {
        if (hasAccidentOnly && species) return IconUtils.getAccidentIcons(species, underwriter);
        if (hasAccidentAndIllness && species) return IconUtils.getAccidentIllnessIcons(species, underwriter);
        return [];
    }, [species, hasAccidentOnly, hasAccidentAndIllness, underwriter]);

    const handleOpenModal = useCallback(
        (ModalComponent: FunctionComponent<any>, modalProps?: any) => {
            modal.openModal(ModalComponent, modalProps || {});
        },
        [modal]
    );

    const isLargeBreakpoint = useMediaQuery("only screen and (min-width: 1024px)");
    const isMediumBreakpoint = useMediaQuery("only screen and (min-width: 768px) and (max-width: 1023px)");
    const isSmallBreakpoint = useMediaQuery("only screen and (min-width: 640px) and (max-width: 767px)");

    const initialViewableCount = useMemo(() => {
        if (isLargeBreakpoint) return 20;
        if (isMediumBreakpoint) return 12;
        if (isSmallBreakpoint) return 9;
        return 8;
    }, [isLargeBreakpoint, isMediumBreakpoint, isSmallBreakpoint]);

    return (
        <Card className="text-type-primary flex flex-col items-center gap-4 bg-background-primary px-4 py-6">
            <Heading level="h3" styleAs="h4" className="w-full text-center text-sm font-bold">
                {getTitle()}
            </Heading>
            <IconGrid
                gridItems={coverageIcons}
                wrapperClass="grid grid-cols-2 sm:grid-cols-3 md:flex md:flex-wrap md:justify-center"
                initialViewableCount={initialViewableCount}
                isLargeScreen={isLargeBreakpoint}
                itemClass="w-full"
            />
            <div className="flex w-full justify-center">
                <Button
                    onClick={() => handleOpenModal(modalContent as React.FC<DialogProps>, { samplePolicyUrl })}
                    className="text-xs"
                    variant="ghost"
                    startDecorator={<InfoIcon className="h-4" />}
                    size="sm"
                >
                    Full Coverage Details
                </Button>
                {modal.render}
            </div>
        </Card>
    );
}
