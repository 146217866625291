import { useQuery } from "@tanstack/react-query";
import { QuoteAPI } from "../utils/QuoteAPI";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";

export const useBreeds = (underwriter?: PetUnderwriterType) => {
    const quoteApi = new QuoteAPI(underwriter as PetUnderwriterType);
    const breedQuery = useQuery({
        queryKey: [`breeds`],
        queryFn: async () => {
            return quoteApi.getBreeds();
        }
    });

    const { data, isFetching, error } = breedQuery;
    // TO-DO add useMutation hook for stateCode value

    return { breeds: data, breedsLoading: isFetching, breedsError: error };
};
