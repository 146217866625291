import { useEffect } from "react";
import { Button } from "./ui";
import { motion, Variants, AnimatePresence } from "framer-motion";

import { Container } from "./Container";

// assets
import CloseIcon from "../media/icons/Close.svg";
import PaperIcon from "../media/icons/Illustrated-Ripped-Document.svg";
import RedoIcon from "../media/icons/arrow-rotate-left.svg";

type QuoteResetModalProps = {
    onReset: () => void;
    isVisible: boolean;
};

export const QuoteResetModal = ({ onReset, isVisible }: QuoteResetModalProps) => {
    const handleReset = () => {
        onReset();
    };

    const variants: Variants = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 }
    };

    useEffect(() => {
        if (isVisible) {
            // Capture current scroll position and existing body styles
            const scrollY = window.scrollY;
            const originalPosition = document.body.style.position;
            const originalTop = document.body.style.top;
            const originalWidth = document.body.style.width;

            // Prevent scrolling and fix position
            document.body.style.position = "fixed";
            document.body.style.top = `-${scrollY}px`;
            document.body.style.width = "100%";

            // Reset to original state when component unmounts
            return () => {
                document.body.style.position = originalPosition;
                document.body.style.top = originalTop;
                document.body.style.width = originalWidth;
                window.scrollTo(0, scrollY);
            };
        }
    }, [isVisible]);

    return (
        <AnimatePresence mode="wait">
            {isVisible && (
                <motion.div
                    key="modal-body"
                    variants={variants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    className="fixed inset-0 z-[9999] flex h-screen w-screen items-center justify-center"
                >
                    <div className="fixed inset-0 z-10 h-screen w-screen bg-background-primary-invert opacity-30"></div>
                    <Container>
                        <div className="flex justify-center">
                            <motion.div className="relative z-20 flex w-full max-w-[400px] flex-col items-center gap-8 rounded-2xl bg-background-secondary p-8">
                                <button type="button" onClick={handleReset} className="absolute right-4 top-4 flex size-10 items-center justify-center">
                                    <CloseIcon />
                                </button>
                                <div>
                                    <PaperIcon />
                                </div>
                                <div>
                                    <h2 className="text-center text-[18px] font-semibold">{`The dog ate our homework :(`}</h2>
                                    <p className="mt-1 text-center text-[14px]">{`We were unable to load your quote, sorry about that.`}</p>
                                </div>
                                <Button type="button" onClick={handleReset}>
                                    <div className="flex items-center gap-4">
                                        <RedoIcon />
                                        <span>Start Over</span>
                                    </div>
                                </Button>
                            </motion.div>
                        </div>
                    </Container>
                </motion.div>
            )}
        </AnimatePresence>
    );
};
