"use client";

import * as React from "react";
import useEmblaCarousel, { type UseEmblaCarouselType } from "embla-carousel-react";
import { ArrowLeft, ArrowRight } from "lucide-react";

import { cn } from "@/shared/utils";
import { Button } from "@/shared/components/ui/Button";
import IndexIndicator from "@/shared/components/IndexIndicator";
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

type CarouselProps = {
    options?: CarouselOptions;
    plugins?: CarouselPlugin;
    orientation?: "horizontal" | "vertical";
    setApi?: (api: CarouselApi) => void;
    disabled?: boolean;
};

type CarouselContextProps = {
    carouselRef: ReturnType<typeof useEmblaCarousel>[0];
    api: ReturnType<typeof useEmblaCarousel>[1];
    scrollPrev: () => void;
    scrollNext: () => void;
    canScrollPrev: boolean;
    canScrollNext: boolean;
} & CarouselProps;

type CarouselContentWrapperProps = {
    contentWrapperClass?: string;
};

const CarouselContext = React.createContext<CarouselContextProps | null>(null);

function useCarousel() {
    const context = React.useContext(CarouselContext);

    if (!context) {
        throw new Error("useCarousel must be used within a <Carousel />");
    }

    return context;
}

const Carousel = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & CarouselProps>(
    ({ orientation = "horizontal", options, setApi, plugins, className, children, disabled, ...props }, ref) => {
        const defaultPlugins = [WheelGesturesPlugin()];
        const carouselPlugins = plugins ? [...defaultPlugins, ...plugins] : defaultPlugins;
        const [carouselRef, api] = useEmblaCarousel(
            {
                ...options,
                axis: orientation === "horizontal" ? "x" : "y"
            },
            carouselPlugins
        );
        const [canScrollPrev, setCanScrollPrev] = React.useState(false);
        const [canScrollNext, setCanScrollNext] = React.useState(false);

        const onSelect = React.useCallback(
            (api: CarouselApi) => {
                if (!api || disabled) {
                    return;
                }

                setCanScrollPrev(api.canScrollPrev());
                setCanScrollNext(api.canScrollNext());
            },
            [disabled]
        );

        const scrollPrev = React.useCallback(() => {
            api?.scrollPrev();
        }, [api]);

        const scrollNext = React.useCallback(() => {
            api?.scrollNext();
        }, [api]);

        const handleKeyDown = React.useCallback(
            (event: React.KeyboardEvent<HTMLDivElement>) => {
                if (event.key === "ArrowLeft") {
                    event.preventDefault();
                    scrollPrev();
                } else if (event.key === "ArrowRight") {
                    event.preventDefault();
                    scrollNext();
                }
            },
            [scrollPrev, scrollNext]
        );

        React.useEffect(() => {
            if (!api || !setApi) {
                return;
            }

            setApi(api);
        }, [api, setApi]);

        React.useEffect(() => {
            if (!api) {
                return;
            }

            onSelect(api);
            api.on("reInit", onSelect);
            api.on("select", onSelect);

            return () => {
                api?.off("select", onSelect);
            };
        }, [api, onSelect]);

        React.useEffect(() => {
            api?.reInit({
                ...options,
                active: !disabled
            });
        }, [disabled, api, options]);

        return (
            <CarouselContext.Provider
                value={{
                    carouselRef,
                    api: api,
                    options,
                    orientation: orientation || (options?.axis === "y" ? "vertical" : "horizontal"),
                    scrollPrev,
                    scrollNext,
                    canScrollPrev,
                    canScrollNext
                }}
            >
                <div ref={ref} onKeyDownCapture={handleKeyDown} className={cn("relative", className)} role="region" aria-roledescription="carousel" {...props}>
                    {children}
                </div>
            </CarouselContext.Provider>
        );
    }
);
Carousel.displayName = "Carousel";

const CarouselContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & CarouselContentWrapperProps>(
    ({ className, contentWrapperClass, ...props }, ref) => {
        const { carouselRef, orientation } = useCarousel();

        return (
            <div ref={carouselRef} className={cn("overflow-hidden", contentWrapperClass)}>
                <div ref={ref} className={cn("flex", orientation === "horizontal" ? "" : "-mt-4 flex-col", className)} {...props} />
            </div>
        );
    }
);
CarouselContent.displayName = "CarouselContent";

const CarouselItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => {
    const { orientation } = useCarousel();

    return (
        <div
            ref={ref}
            role="group"
            aria-roledescription="slide"
            className={cn("min-w-0 shrink-0 grow-0 basis-full", orientation === "horizontal" ? "pl-2" : "pt-4", className)}
            {...props}
        />
    );
});
CarouselItem.displayName = "CarouselItem";

const CarouselPrevious = React.forwardRef<HTMLButtonElement, React.ComponentProps<typeof Button>>(({ className, variant = "outline", size = "icon", ...props }, ref) => {
    const { orientation, scrollPrev, canScrollPrev } = useCarousel();

    return (
        <Button
            ref={ref}
            variant={variant}
            className={cn(
                "absolute  size-8 rounded-full",
                orientation === "horizontal" ? "-left-12 top-1/2 -translate-y-1/2" : "-top-12 left-1/2 -translate-x-1/2 rotate-90",
                className
            )}
            disabled={!canScrollPrev}
            onClick={scrollPrev}
            {...props}
        >
            <ArrowLeft className="size-4" />
            <span className="sr-only">Previous slide</span>
        </Button>
    );
});
CarouselPrevious.displayName = "CarouselPrevious";

const CarouselNext = React.forwardRef<HTMLButtonElement, React.ComponentProps<typeof Button>>(({ className, variant = "outline", size = "icon", ...props }, ref) => {
    const { orientation, scrollNext, canScrollNext } = useCarousel();

    return (
        <Button
            ref={ref}
            variant={variant}
            className={cn(
                "absolute size-8 rounded-full",
                orientation === "horizontal" ? "-right-12 top-1/2 -translate-y-1/2" : "-bottom-12 left-1/2 -translate-x-1/2 rotate-90",
                className
            )}
            disabled={!canScrollNext}
            onClick={scrollNext}
            {...props}
        >
            <ArrowRight className="size-4" />
            <span className="sr-only">Next slide</span>
        </Button>
    );
});
CarouselNext.displayName = "CarouselNext";

const CarouselNavDots = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>((props, ref) => {
    const { api } = useCarousel();
    const [updateState, setUpdateState] = React.useState(false);
    const toggleUpdateState = React.useCallback(() => setUpdateState(prevState => !prevState), []);

    React.useEffect(() => {
        if (api) {
            api.on("select", toggleUpdateState);
            api.on("reInit", toggleUpdateState);

            return () => {
                api.off("select", toggleUpdateState);
                api.off("reInit", toggleUpdateState);
            };
        }
    }, [api, toggleUpdateState]);

    const numberOfSlides = api?.scrollSnapList().length || 0;
    const currentSlide = api?.selectedScrollSnap() || 0;

    if (numberOfSlides > 1) {
        return (
            <div ref={ref} className={cn(`flex justify-center gap-2`, props.className)}>
                {Array.from({ length: numberOfSlides }, (_, i) => (
                    <IndexIndicator
                        key={i}
                        index={i}
                        label="slide"
                        handleClick={() => api?.scrollTo(i)}
                        className={`${i === currentSlide ? "bg-background-brand-secondary" : ""}`}
                    />
                ))}
            </div>
        );
    } else {
        return <></>;
    }
});
CarouselNavDots.displayName = "CarouselNavDots";

export { type CarouselApi, Carousel, CarouselContent, CarouselItem, CarouselPrevious, CarouselNext, CarouselNavDots };
