import { cn } from "../utils";
import LoadingSpinner from "./LoadingSpinner";

interface LoaderWithTextProps {
    text: string;
    className?: string;
    spinnerSize?: "sm" | "md";
}

export default function LoaderWithText(props: LoaderWithTextProps) {
    const { text, className, spinnerSize } = props;
    return (
        <div className={cn("flex flex-row items-center justify-start gap-4 text-content-primary", className)}>
            <LoadingSpinner size={spinnerSize} />
            <span>{text}</span>
        </div>
    );
}
