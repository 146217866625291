import React from "react";
import LoaderWithText from "./LoaderWithText";
import { Dialog, DialogContent } from "./ui";

export function LoaderModal() {
    return (
        <Dialog open={true}>
            <DialogContent className="items-center justify-center">
                <LoaderWithText text="Wrapping up..." />
            </DialogContent>
        </Dialog>
    );
}
